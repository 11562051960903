<template>
    <div class="contact-page">
        <div class="bannar">
            <!-- <img src="../../image/bg.png"> -->
            <!-- <img v-if="bannerSrc" :src="bannerSrc">
            <img v-else src="../../image/bg.png"> -->
            <img :src="bannerSrc" v-if="bannerType">
            <video v-else muted="muted" 
            id="startvideo" class="video" 
            :src="bannerSrc" type="video/mp4"  
            autoplay="autoplay" controls="controls" 
            loop="-1" x5-playsinline webkit-playsinline="true" playsinline="true">
                <p>你的浏览器不支持video标签.</p>
            </video>
            <canvas
                id="mycanvas"
                style="display: none"
            ></canvas>
            <!-- <img :src="imgsrc"> -->
            <div class="overlay"></div>
            <div class="bannar-info">
                <div class="bannar-title">联系我们</div>
                <div class="bannar-detail">{{bannerTitle}}</div>
            </div>
        </div>
        <main>
            <div class="main-map">
                <div class="map-bg">
                    <div class="map" id="container"></div>
                </div>
                <div class="map-info">
                    <div class="content" v-html="info.content">
                    </div>
                    <div class="detail">
                        <img src="../../../image/weizhi.png">
                        <div class="detail-content">
                            地址：{{info.address}}
                        </div>
                    </div>
                    <div class="detail">
                        <img src="../../../image/dianhua.png">
                        <div class="detail-content">
                            联系电话：{{info.phone}}
                        </div>
                    </div>
                    <div class="detail">
                        <img src="../../../image/xin.png">
                        <div class="detail-content">
                            联系邮箱：{{info.email}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="main-contact">
                <div class="title">反馈留言</div>
                <div class="title-detail">
                    请留下你的信息，我们会在第一时间与你取得联系
                </div>
                <el-form ref="form" :model="form" :rules="rules">
                    <div class="content">
                        <div class="content-item">
                            <el-form-item prop="name">
                                <el-input type="text" v-model="form.name" placeholder="你的姓名">
                                </el-input>
                            </el-form-item>
                            <!-- <input type="text" v-model="form.name" placeholder="你的姓名"> -->
                        </div>
                        <div class="content-item">
                            <el-form-item prop="phone">
                                <el-input type="text" v-model="form.phone" placeholder="你的电话">
                                </el-input>
                            </el-form-item>
                            <!-- <input type="text" v-model="form.phone" placeholder="你的电话"> -->
                        </div>
                        <div class="content-item">
                            <el-form-item prop="email">
                                <el-input type="text" v-model="form.email" placeholder="你的邮箱">
                                </el-input>
                            </el-form-item>
                            <!-- <input type="text" v-model="form.email" placeholder="你的邮箱"> -->
                        </div>
                    </div>
                    <div class="content">
                        <div class="content-info">
                            <el-form-item prop="content">
                                <el-input type="textarea" maxlength="1000" :autosize="{ minRows: 2, maxRows: 3}" show-word-limit v-model="form.content" placeholder="留言">
                                </el-input>
                                <!-- <span style="position: absolute; right: 6px; bottom: 10px;">0/64</span> -->
                            </el-form-item>
                            <!-- <input type="text" v-model="form.content" placeholder="留言"> -->
                        </div>
                        <div class="content-sub" @click="submitClick">提交</div>
                    </div>
                </el-form>
            </div>
        </main>
    </div>
</template>
<script>
import {getBanner,getContact,getOur} from '../../../api/homeApi'

export default {
    data() {
        var validatePass = (rule, value, callback) => {
            var CellPoe = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
            if (value === '') {
                callback(new Error('请输入手机号码'));
            } 
            setTimeout(() => {
                if(!Number.isInteger(+value)) {
                    callback(new Error('请输入数字'));
                }else {
                    if (!CellPoe.test(value)) {
                        callback(new Error('请输入正确的手机格式!'));
                    } else {
                        callback();
                    }
                }
            },100)
        };
        return {
            bannerSrc: '',
            bannerType: 1, //1.图片 0.视频
            bannerTitle: '',
            form: {
                name: '',
                phone: '',
                email: '',
                content: '',
            },
            rules: {
                name: [
                    { required: true, message: '请填写姓名', trigger: 'blur' },
                ],
                phone: [
                    // { required: true, message: '请填写手机号码', trigger: 'blur' },
                    { validator: validatePass, trigger: 'blur' }
                ],
                content: [
                    { required: true, message: '请填写留言', trigger: 'blur' },
                ],
                email: [
                    {type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur'] }
                ],
            },
            map: null,
            info: {},
            imgsrc: '',
        }
    },
    created() {
        this.getBanner()
        this.init()
        this.getInfo()
    },
    methods: {
        _isMobile() {
            let flag = navigator.userAgent.match(
            /(phone|pad|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows phone)/i
            );
            return flag;
        },
        init() {
            this.$nextTick(() => {
                // 创建地图实例
                const map = new AMap.Map('container', {
                    resizeEnable: true,
                    zoom: 11,
                    // center: [100.23,102.89],
                })
                this.map = map
                const geocoder = new AMap.Geocoder({
                    city: "成都", //城市设为北京，默认：“全国”
                });
                var marker = new AMap.Marker();
                // marker.setPosition([104.432884,30.488891]);
                // map.add(marker);
                // map.setFitView(marker);
                var address  = '简阳市黄楝坪路888号'
                geocoder.getLocation(address, function(status, result) {
                    if (status === 'complete'&&result.geocodes.length) {
                        var lnglat = result.geocodes[0].location
                        console.log(lnglat)
                        marker.setPosition(lnglat);
                        map.add(marker);
                        map.setFitView(marker);
                        map.setZoom(15)
                    }else{
                        log.error('根据地址查询位置失败');
                    }
                });
            })
            
        },
        _isMobile() {
            let flag = navigator.userAgent.match(
            /(phone|pad|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows phone)/i
            );
            return flag;
        },
        getBanner() {
            getBanner({
                plate: 5,
            }).then(res => {
                if(res.code == 0) {
                    if(res.infos.length > 0) {
                        if(this._isMobile()) {
                            this.bannerType = 1
                            this.bannerSrc = JSON.parse(res.infos[0].mobileImage)[0].url
                        }else {
                            let arr = JSON.parse(res.infos[0].image)
                            if([
                                'image/png',    // .png
                                'image/jpeg',   // .jpg .jpeg
                                'image/x-ms-bmp', // .bmp
                                'image/gif',    // .gif
                                'image/tiff',   // .tif .tiff
                            ].includes(arr[0].type)) {
                                this.bannerType = 1
                            }else {
                                this.bannerType = 0
                            }
                            this.bannerSrc = JSON.parse(res.infos[0].image)[0].url
                        }
                        this.bannerTitle = res.infos[0].content
                    }
                }
            })
        },
        submitClick() {
            this.$refs['form'].validate((valid) => {
                if(!valid) return
                getContact({
                    ...this.form
                }).then(res => {
                    if(res.code == 0) {
                        this.$message({
                            message: '信息提交成功',
                            type: 'success'
                        });
                    }
                })
            });
        },
        getInfo() {
            getOur().then(res => {
                if(res.code == 0) {
                    this.info = res.info
                }
            })
        },
    },
}
</script>
<style lang="less" scoped>
@media screen and (max-width:900px) {
    main {
        padding: 0;
        padding-bottom: 80px;
        .main-map {
            flex-direction: column;
            .map-bg {
                padding: 40px;
                padding-bottom: 0;
                background-color: #F1F1F1;
            }
            .map {
                width: 100%;
                height: 6rem;
                border: 1px solid #ccc;
            }
            .map-info {
                /deep/.content {
                    font-size: 28px;
                    p {
                        span {
                            font-size: 28px !important;
                            background-color: transparent !important;
                            color: #666 !important;
                            display: inline-block;
                            text-indent: 2em;
                        }
                    }
                }
            }
        }
    }
    .detail {
        img {
            width: .4rem;
            height: .4rem;
        }
        .detail-content {
            font-size: .28rem;
        }
    }
    .main-contact {
        .title {
            font-size: 42px;
        }
        .title-detail {
            font-size: .24rem;
        }
    }
    .content {
        display: flex;
        flex-wrap: wrap;
        .content-item:nth-last-child(1) {
            margin-right: 0;
        }
        .content-item {
            width: 100%;
            // margin-right: .12rem;
            height: .6rem;
            // background: #fff;
            margin-bottom: .2rem;
            .el-input {
                font-size: 24px;
            }
            input {
                width: 100%;
                border: none;
                line-height: .6rem;
                box-sizing: border-box;
                padding: 0 .2rem;
                outline: none;
                font-size: 24px;
            }
        }
        .content-info {
            width: 100%;
            // margin-right: .12rem;
            // height: .6rem;
            // background: #fff;
            input {
                width: 100%;
                border: none;
                line-height: .6rem;
                box-sizing: border-box;
                padding: 0 .2rem;
                outline: none;
                font-size: 24px;
            }
            .el-textarea {
                font-size: 24px;
            }
        }
        .content-sub {
            background-color: #EE6A23;
            height: .6rem;
            font-size: .28rem;
            color: #fff;
            flex: 1;
            text-align: center;
            line-height: .6rem;
            border-radius: .01rem;
            cursor: pointer;
            margin-top: 20px;
        }
    }
    .contact-page {
        margin-top: 20px;
        .bannar {
            .overlay {
                position: absolute;
                height: 100%;
                width: 100%;
                left: 0;
                top: 0;
                background: rgba(0,0,0,.4);
                z-index: 9;
            }
            img {
                // height: 550px;
                vertical-align: middle;
                width: 100%;
                height: 30vw;
            }
            .bannar-info {
                top: 20%;
                padding: 0 20px;
            }
        }
    }
}
@media screen and (min-width:900px) {
    main {
        width: 12rem;
        padding: .8rem 0rem;
    }
    .main-map {
        .map-bg {
            width: 50%;
            flex-shrink: 0;
            box-sizing: border-box;
            border: 1px solid #ccc;
            .map {
                width: 100%;
                height: 100%;
            }
        }
        .map-info {
            /deep/.content {
                font-size: .12rem;
                p {
                    span {
                        background-color: transparent !important;
                        color: #666 !important;
                        display: inline-block;
                        text-indent: 2em;
                    }
                }
            }
        }
    }
    .detail {
        img {
            width: .2rem;
            height: .2rem;
        }
        .detail-content {
            font-size: .14rem;
        }
    }
    .main-contact {
        .title {
            font-size: 32px;
        }
        .title-detail {
            font-size: .14rem;
        }
    }
    .content {
        display: flex;
        .content-item:nth-last-child(1) {
            margin-right: 0;
        }
        .content-item {
            width: calc(100%/3 - .24rem/3);
            margin-right: .12rem;
            height: .6rem;
            // background: #fff;
            margin-bottom: .2rem;
            input {
                width: 100%;
                border: none;
                line-height: .6rem;
                box-sizing: border-box;
                padding: 0 .2rem;
                outline: none;
            }
        }
        .content-info {
            width: 66%;
            margin-right: .12rem;
            // height: .6rem;
            // background: #fff;
            input {
                width: 100%;
                border: none;
                line-height: .6rem;
                box-sizing: border-box;
                padding: 0 .2rem;
                outline: none;
            }
        }
        .content-sub {
            background-color: #EE6A23;
            height: .6rem;
            font-size: .18rem;
            color: #fff;
            flex: 1;
            text-align: center;
            line-height: .6rem;
            border-radius: .01rem;
            cursor: pointer;
        }
    }
    .contact-page {
        .bannar {
            img {
                // height: 550px;
                vertical-align: middle;
                width: 100%;
                height: 5.5rem;
            }
            .bannar-info {
                top: 40%;
            }
        }
    }
}
    .contact-page {
        width: 100%;
        .bannar {
            width: 100%;
            // height: 5.5rem;
            background: #666;
            position: relative;
            img {
                // height: 550px;
                vertical-align: middle;
                width: 100%;
            }
            .bannar-info {
                position: absolute;
                left: 0;
                width: 100%;
                text-align: center;
                z-index: 9;
                .bannar-title {
                    font-size: .36rem;
                    color: #fff;
                    margin-bottom: .2rem;
                }
                .bannar-detail {
                    font-size: .24rem;
                    color: #fff;
                }
            }
        }
        main {
            // width: 1200px;
            // width: 12rem;
            box-sizing: border-box;
            // padding: .8rem 0rem;
            margin: 0 auto;
            .main-map {
                display: flex;
                margin-bottom: .8rem;
                .map {
                    // width: 6rem;
                    // height: 4.6rem;
                   
                }
                .map-info {
                    background-color: #F1F1F1;
                    color: #666;
                    box-sizing: border-box;
                    padding: .4rem;
                    .content {
                        // font-size: 0.12rem;
                        line-height: 2;
                        margin-bottom: .3rem;
                        //  display: -webkit-box;
                        // -webkit-line-clamp:8;
                        // -webkit-box-orient: vertical;
                        // text-overflow: ellipsis;
                        // overflow: hidden;
                    }
                    .detail:nth-last-child(1) {
                        margin-bottom: 0;
                    }
                    .detail {
                        display: flex;
                        margin-bottom: .25rem;
                        align-content: center;
                        img {
                            // width: .2rem;
                            // height: .2rem;
                            // background-color: #EE6A23;
                            margin-right: .05rem;
                        }
                        .detail-content {
                            // font-size: .12rem;
                        }
                    }
                }
            }
            .main-contact {
                box-sizing: border-box;
                padding: .5rem 1rem;
                background-color: #F1F1F1;
                .title {
                    // font-size: .32rem;
                    width: 100%;
                    text-align: center;
                    margin-bottom: .1rem;
                    color: #333;
                }
                .title-detail {
                    // font-size: .14rem;
                    margin-bottom: .3rem;
                    color: #666;
                    text-align: center;
                }
                
            }
            .content {
                /deep/.content-info {
                    .el-textarea__inner {
                        // padding-bottom: 36px;
                    }
                    .el-input__count {
                        bottom: -35px;
                        background-color: transparent;
                        right: 0;
                    }
                }
                /deep/.content-item {
                    .el-form-item__content {
                        line-height: 60px;
                        background-color: #fff;
                    }
                    input {
                        border: none;
                    }
                }
            }
        }
    }
</style>